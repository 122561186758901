import Urls from '@/config/Urls';
import clientId from '@/utils/clientId';
import type {AppRouter} from '@server-api-trpc/appRouter';
import {createTRPCClient, httpLink} from '@trpc/client';
import type {inferRouterOutputs} from '@trpc/server';
import superjson from 'superjson';

// only used for test code
export const trpcCookie = {
  value: '',
};

// need type annotation due to composite typescript project referencing server project
export const trpc: ReturnType<typeof createTRPCClient<AppRouter>> = createTRPCClient<AppRouter>({
  links: [
    httpLink({
      url: `${Urls.ApiServer}/trpc`,
      transformer: superjson,
      headers() {
        if (typeof window === 'undefined') {
          return {
            Cookie: trpcCookie.value,
          };
        }
        return {};
      },
      async fetch(url, options) {
        // this is fine
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        const urlString = url.toString();

        // don't use our `Fetch` wrapper here because it messes up trpc error messages that are shown to users
        const response = await fetch(urlString, {
          ...options,
          body: options?.body || undefined,
          headers: {
            ...options?.headers,
            'x-client-id': clientId,
          },
          credentials: 'include',
        });
        if (typeof window === 'undefined') {
          // we're running this in tests, which don't have cookie management. manually maintain them
          const setCookieHeader = response.headers.get('set-cookie');
          if (setCookieHeader) {
            trpcCookie.value = setCookieHeader
              .split('SameSite=Strict,')
              .map((value) => {
                return value.split(';')[0];
              })
              .join(';');
          }
        }
        return response;
      },
    }),
  ],
  /**
   * @link https://tanstack.com/query/v5/docs/reference/QueryClient
   */
  // queryClientConfig: {
  //   defaultOptions: {
  //     queries: {staleTime: 60},
  //   },
  // },
  //   };
  // },
});

export type RouterOutputs = inferRouterOutputs<AppRouter>;
